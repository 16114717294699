(() => {

	const SELECTOR_LAZY = '.lazy';
	const SELECTOR_IMG_LOADER = '.image-loader';
	//const SELECTOR_IMG_RESP = '.img-responsive';

	const CLASSNAME_FADEOUT = 'fade-out';
	const CLASSNAME_LOADED = 'loaded';

	const observer = new MutationObserver((mutations) => {
		mutations.forEach((mutation) => {
			if (mutation.attributeName === "class") {
				//var attributeValue = $(mutation.target).prop(mutation.attributeName);
				//var target = $(mutation.target)
				if (mutation.target.classList.contains(CLASSNAME_LOADED)) {
					const loader = mutation.target.parentElement.querySelector(SELECTOR_IMG_LOADER);
					loader.classList.add(CLASSNAME_FADEOUT);
				}
			}
		});
	});

	const lazies = document.querySelectorAll(SELECTOR_LAZY);
	lazies.forEach((lazy) => {
		observer.observe(lazy, { attributes: true });
	})

})();