let opener = $('.toggler');
let closer = $('.close-menu')
let menu = $('.nav-wrapper')

opener.on('click',function(el){
	menu.addClass('opened')
})
closer.on('click',function(el){
	menu.removeClass('opened')
})
