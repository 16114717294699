//import css
import "./scss/main.scss";

//import components js
import "./components/backtotop/backtotop.js";
import "./components/button/button.js";
import "./components/card/card.js";
import "./components/cols-3/cols-3.js";
import "./components/cookie/cookie.js";
import "./components/footer/footer.js";
import "./components/gallery/gallery.js";
import "./components/header/header.js";
import "./components/hero-card/hero-card.js";
import "./components/hero/hero.js";
import "./components/image-loader/image-loader.js";
import "./components/image/image.js";
import "./components/privacy/privacy.js";
import "./components/skiplinks/skiplinks.js";
import "./components/spinner/spinner.js";
import "./components/swiper-slide/swiper-slide.js";
import "./components/swiper/swiper.js";
import "./components/title-text/title-text.js";

import "./js/browser-detect";
import "./js/lazyload";
