import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
Swiper.use([Autoplay, EffectFade, Navigation ]);
import 'swiper/css';
let autoplaySettings;
autoplaySettings = {
	delay: 5000,
	disableOnInteraction: false,
}

const swiper = new Swiper('.swiper-carousel', {

	// configure Swiper to use modules
	loop: true,
	autoplay: autoplaySettings,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});
